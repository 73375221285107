import { lighten, useTheme, alpha } from '@mui/system';
import { useElementSize } from 'usehooks-ts';
import { CustomTheme } from '../../theme/CustomTheme';
import { CSSProperties, useMemo, useState } from "react";
import { Stack, Typography } from '@mui/material';
import { AreaChart, Area, XAxis, Tooltip } from 'recharts';

export interface IDashboardChart {
    height: CSSProperties["height"];
    title: string;
    subtitle?: string;
    data : any[];
    lookupValues : string[];
}

function DashboardChart(props: IDashboardChart) {
  const theme = useTheme<CustomTheme>();
  const [colors] = useState<Array<string>>(['#1C115C','#3366F3','#05423D', '#32B775', '#F49400', '#F5CA48', '#FF8E52', '#FFBE98', '#3D201A', '#602B1D']);

;
  const [squareRef, { width, height }] = useElementSize()
  const lightColor = useMemo(() => {
    return colors.map(o => lighten(o, 0.7));
  }, [colors]);

  const lighterColor = useMemo(() => {
    return lightColor.map(o => alpha(lighten(o, 0.9), 0.2));
  }, [lightColor]);

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        padding={2}
        whiteSpace="nowrap"
      >
        <Typography variant="subtitle1" component="h2">
          {props.title}
        </Typography>
        {props.subtitle ? <Typography variant="subtitle1" component="h3" fontWeight="normal" textOverflow="ellipsis" overflow="hidden">
          {props.subtitle}
        </Typography> : props.subtitle}
      </Stack>
      <div ref={squareRef} style={{ width: '100%', height: props.height }}>
        <AreaChart width={width} height={height} data={props.data} margin={{ top: 0, left: 0, bottom: 0, right: 0 }} style={{ borderBottomLeftRadius: theme.shape.borderRadius, borderBottomRightRadius: theme.shape.borderRadius }}>

          <defs>
            { props.lookupValues.map(o => {
                    return (
                        <linearGradient id={`CoolGradient${props.lookupValues.indexOf(o)}`} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor={lightColor[props.lookupValues.indexOf(o)]} />
                            <stop offset="80%" stopColor={lighterColor[props.lookupValues.indexOf(o)]} />
                        </linearGradient>
                    )
                    }) 
            }
          </defs>
          <Tooltip />
          { props.lookupValues.map(o => {
                    return (
                        <Area fill={`url(#CoolGradient${props.lookupValues.indexOf(o)})`} dot={false} type={'monotoneX'} dataKey={o} stroke={colors[props.lookupValues.indexOf(o)]} strokeWidth={3} />
                    )
                    }) 
            }
          <XAxis dataKey="Date" hide />
          {/* <YAxis dataKey="y" hide /> */}
        </AreaChart>
      </div>
    </>
  );
};

export default DashboardChart;