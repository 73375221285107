import { useCallback, useMemo } from "react";
import { Grid, Paper } from '@mui/material';
import { useFetch } from '../../hooks/useFetch';
import { DashboardAnalytics } from '@aviation/dashboard-service-sdk';
import { useParams } from 'react-router-dom';
import DashboardChart from './DashboardChart';
import dayjs from "dayjs";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export interface IDashboardChartsProps {
    dateFrom?: string;
    dateTo?: string;
}

function DashboardCharts(props : IDashboardChartsProps) {
    const { clientCode } = useParams();
    const data = useFetch<DashboardAnalytics | undefined>(`/api/dashboard/${clientCode}/from/${props.dateFrom}/to/${props.dateTo}`, (response) => response, 0, true).data;

    const haulTypes = useMemo(() => {
        return (data?.HaulTypes ?? []).sort((a, b) => a < b ? -1 : 1);
    }, [data]);

    const serviceTypes = useMemo(() => {
        return (data?.ServiceTypes ?? []).sort((a, b) => a < b ? -1 : 1);
    }, [data]);

    // const flights = useMemo(() => {
    //     return (data?.FlightAnalytics ?? []).map(o => {
    //         return { ...o, Date: dayjs(o.Day ?? '2000-01-01').format('DD.MM.YYYY')}
    //     })
    // }, [data]);

    // const meals = useMemo(() => {
    //     return (data?.ServiceTypeAnalytics ?? []).map(o => {
    //         return { ...o, Date: dayjs(o.Day ?? '2000-01-01').format('DD.MM.YYYY')}
    //     })
    // }, [data]);

    const flightsForAirline = useCallback((airline : string) => {
        return (data?.FlightAnalytics ?? []).filter(o => o.Group === airline).flatMap(o => {
            return o.Items?.map(item => { 
                return {...item, Date: dayjs(item.Day ?? '2000-01-01').format('DD.MM.YYYY')}
            })
        });
    }, [data])

    const mealsForAirline = useCallback((airline : string) => {
        return (data?.ServiceTypeAnalytics ?? []).filter(o => o.Group === airline).flatMap(o => {
            return o.Items?.map(item => { 
                return {...item, Date: dayjs(item.Day ?? '2000-01-01').format('DD.MM.YYYY')}
            })
        });
    }, [data])

    const airlines = useMemo(() => {
        var unique = new Set<string>();
        data?.FlightAnalytics?.forEach(o => unique.add(o.Group ?? 'Unkown'));
        data?.ServiceTypeAnalytics?.forEach(o => unique.add(o.Group ?? 'Unkown'));
        return Array.from(unique).filter(o => o !== 'Total');
    }, [data]);

    return (
        <Grid container spacing={3} marginLeft={0} marginTop={0.0}>
            { airlines.map(airline => 
                (
                    <>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                            <DashboardChart title={`${airline} Routes`} subtitle="Per haul type" height={150} data={flightsForAirline(airline)} lookupValues={haulTypes} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} elevation={3}>
                        <DashboardChart title={`${airline} Meals`} subtitle="Per service type" height={150} data={mealsForAirline(airline)} lookupValues={serviceTypes} />
                        </Paper>
                    </Grid>
                    </>
                )
            )}
            
        </Grid>
            
        
    );

};

export default DashboardCharts;